import React, { Suspense, lazy }  from 'react'
import {BrowserRouter as Router} from "react-router-dom";
import './App.scss';
import "bootstrap/dist/css/bootstrap.min.css";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const Header = lazy(() => import('./components/Header'));
const Footer = lazy(() => import('./components/Footer'));
const Main = lazy(() => import('./components/Main'));

function App() {
    const { trackPageView } = useMatomo();

    React.useEffect(() => {
        trackPageView()
    }, []);

    return (
        <>
            <div className="App">
                <Router>
                    <Suspense fallback={<div>Loading...</div>}>
                    <Header />
                    <Main />
                    <Footer />
                    </Suspense>
                </Router>
            </div>
        </>
    );
}

export default App;
