import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './App.scss';
import { MatomoProvider,createInstance} from "@datapunt/matomo-tracker-react";

const instance = createInstance({
    urlBase: 'https://mat0m0.silverback-ecom.de/',
    siteId: 2,
    linkTracking: false // Important!
})

ReactDOM.render(
    <MatomoProvider value={instance}>
        <App/>
    </MatomoProvider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
